import React from 'react'

import { Button, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const {
        mergeStatementData,
        listParams, listItems,
        setShowModalShowResultStatement,
        setSelectedShowResultStatement,
        setShowModalProcessedStatement,
        setSelectedProcessedStatement,
        setShowModalDeletedStatement,
        setSelectedDeletedStatement
    } = props

    const onShowResultClicked = (item) => {
        setSelectedShowResultStatement({ ...item })
        setShowModalShowResultStatement(true)
    }

    const onProcessClicked = (item) => {
        setSelectedProcessedStatement({ ...item })
        setShowModalProcessedStatement(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedStatement({ ...item })
        setShowModalDeletedStatement(true)
    }

    return (
        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th>File</th>
                    <th style={{ width: '40%' }}>Rekening</th>
                    <th style={{ width: '80px' }} className="text-center">Hasil</th>
                    <th style={{ width: '100px' }}>Dibuat</th>
                    <th style={{ width: '100px' }}>Diproses</th>
                    {
                        mergeStatementData && mergeStatementData.meta && mergeStatementData.meta.sca
                            ? <th style={{ width: '100px' }}>Selesai</th>
                            : null
                    }
                    <th style={{ width: '1%' }} colSpan={3} className="text-center">Tindakan</th>
                </tr>
            </thead>
            <tbody>
                {listItems?.map((item, index) => (
                    <tr key={item.uuid}>
                        <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                        <td><pre style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{item.file_name || item.uuid}</pre></td>
                        <td style={{ whiteSpace: 'break-spaces' }}>
                            {
                                item && item.bank_statement_detail
                                    ? item.bank_statement_detail.map((detail, index) => {
                                        return `${index + 1}. ${detail.bank_name} ${detail.bank_account_name} ${detail.bank_account_number} ${detail.period}`
                                    }).join('\n')
                                    : null
                            }
                        </td>
                        <td style={{ width: '115px' }} className="text-center">
                            {
                                (item.processed_at) &&
                                (<Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => onShowResultClicked(item)}>
                                    <FontAwesomeIcon icon={faFile} size="lg" />
                                    <p className="m-1 mb-0">Lihat Hasil</p>
                                </Button>)
                            }
                        </td>
                        <td>{item.created_at}</td>
                        <td>{item.processed_at}</td>
                        {
                            mergeStatementData && mergeStatementData.meta && mergeStatementData.meta.sca
                                ? <td>{item.completed_at}</td>
                                : null
                        }
                        <td className="text-center">
                            {
                                (!item.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                (<Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => onProcessClicked(item)}>
                                    <FontAwesomeIcon icon={faCog} size="lg" />
                                    <p className="m-1 mb-0">Proses</p>
                                </Button>)
                            }
                        </td>
                        <td className="text-center">
                            <Button size="sm" variant="link" className="text-decoration-none"
                                onClick={() => onDeleteClicked(item)}>
                                <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                <p className="m-1 mb-0">Hapus</p>
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

export default App
